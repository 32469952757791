import { Topic } from "../../models/topic";
import { useChatStore } from "../../store/Chat.store";

export const handleTopicSelection = (topicSelected: Topic) => {
    useChatStore.setState({ topicSelected, userPrompt: topicSelected.userPrompt });

    setTimeout(() => {
        const scrollDiv = document.getElementById('scrollDiv');
        if (scrollDiv) {
            scrollDiv.scrollTop = scrollDiv.scrollHeight;
        }
    }, 250);
}

import React from 'react';


export const generatePolicy = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Prevent the original form from submitting

    // Get values from the original form
    const fullName = (document.getElementById('formFullName') as HTMLInputElement).value;
    const location = (document.getElementById('formLocation') as HTMLInputElement).value;

    console.log('Generating policy...');
    console.log('Full Name:', fullName);
    console.log('Location:', location);

    const textContent = `Use the Final - AI Policy as the standard template to create an AI Policy for ${location}. Make sure to reference AI guidelines and the AI Policy Manual. Don't leave anything out. The report should be approved by ${fullName}.`;

    // Combine the values into a single string for the prompt
    const combinedText = `Full Name: ${fullName}, Location: ${location}`;

    // Populate the HubSpot form fields
    const hubspotFullNameField = document.getElementById('fullname-9410d0a4-560f-4ba6-99bf-8496ccebd309') as HTMLInputElement | null;
    const hubspotCityField = document.getElementById('city-9410d0a4-560f-4ba6-99bf-8496ccebd309') as HTMLInputElement | null;

    if (hubspotFullNameField && hubspotCityField) {
        hubspotFullNameField.value = fullName;
        hubspotCityField.value = location;

        console.log('HubSpot form fields populated.');

        // Automatically submit the HubSpot form
        const hubspotForm = document.getElementById('hsForm_9410d0a4-560f-4ba6-99bf-8496ccebd309') as HTMLFormElement | null;
        if (hubspotForm) {
            hubspotForm.submit();
            console.log('HubSpot form submitted.');
        } else {
            console.error('Could not find HubSpot form to submit.');
        }
    } else {
        console.error('Could not find HubSpot form fields.');
    }

    // Update userPrompt state with the combined text
    useChatStore.setState({ userPrompt: textContent });

    // Now simulate the click of the `promptEnter` button
    const promptEnterButton = document.getElementById('promptEnter');
    // Usage with delay:
    setTimeout(() => {
        if (promptEnterButton) {
            promptEnterButton.click();
            console.log('Prompt entered and button clicked.');
        } else {
            console.error('Could not find promptEnter button.');
        }

    }, 250);
    
};



